<template>
  <default-modal
    v-model="isModalOpen"
    width="400"
  >
    <v-card-title class="justify-center font-weight-bold"
      >Mesa de Solo</v-card-title
    >
    <v-col cols="12">
      <default-text-field
        disabled
        label="SKU"
        :clearable="false"
        :value="String(groundMount.sku)"
      />
    </v-col>
    <v-col cols="12">
      <default-text-field
        disabled
        label="Descrição"
        :clearable="false"
        :value="groundMount.insumo_descricao"
      />
    </v-col>
    <v-col
      cols="12"
      justify="center"
    >
      <v-form
        ref="form"
        @submit.prevent
      >
        <default-text-field
          v-model="groundMountDivisor"
          type="number"
          hide-spin-buttons
          min="1"
          label="Divisor"
          autofocus
          :hide-details="false"
          :rules="[rules.required, rules.isValid]"
          class="mb-n4"
        />
      </v-form>
    </v-col>
    <v-col cols="12">
      <default-button
        @click="editGroundMount"
        :loading="isLoading"
        >Editar Divisor</default-button
      >
    </v-col>
  </default-modal>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "EditGroundMount",
  components: {
    DefaultButton,
    DefaultModal,
    DefaultTextField,
  },
  data() {
    return {
      isLoading: false,
      groundMountDivisor: "",
    };
  },
  props: {
    value: {},
    groundMount: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editGroundMount() {
      if (this.$refs.form.validate()) {
        this.$emit(
          "editGroundMount",
          this.groundMountToBeEdited,
          this.setIsLoading
        );
      }
    },
    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
  computed: {
    isModalOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    groundMountToBeEdited() {
      return {
        id: this.groundMount.id,
        coeficiente: this.groundMountDivisor,
      };
    },
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
        isValid: (input) =>
          /^[1-9]\d*$/.test(input) || "Informe número inteiro a partir de 1",
      };
    },
  },
};
</script>

<style scoped></style>

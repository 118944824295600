<template>
  <div>
    <listing-head class="d-none d-md-block" />
    <template v-if="!getIsLoadingGroundMounts">
      <listing-row
        v-for="(groundMount, index) in getGroundMounts"
        :key="'groundMount' + index"
        :ground-mount="groundMount"
        @openEditGroundMount="openEditGroundMount"
      />
    </template>
    <default-spinner v-else />
    <edit-ground-mount
      v-model="isEditGroundMountOpen"
      :key="'editGroundMountKey' + editGroundMountKey"
      :ground-mount="groundMount"
      @editGroundMount="editGroundMount"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import EditGroundMount from "./EditGroundMount.vue";
import ListingHead from "./ListingHead.vue";
import ListingRow from "./ListingRow.vue";

export default {
  name: "TheListing",
  components: {
    DefaultSpinner,
    EditGroundMount,
    ListingHead,
    ListingRow,
  },
  data() {
    return {
      groundMount: {},
      editGroundMountKey: 0,
      isEditGroundMountOpen: false,
    };
  },
  created() {
    this.groundMounts();
  },
  methods: {
    ...mapActions("groundMounts", ["groundMounts"]),
    openEditGroundMount(groundMount) {
      this.editGroundMountKey++;
      this.groundMount = groundMount;
      this.isEditGroundMountOpen = true;
    },
    async editGroundMount(groundMount, setIsLoading) {
      setIsLoading(true);
      const responseStatus = await this.$store.dispatch(
        "groundMounts/editGroundMount",
        groundMount
      );
      if (responseStatus === 200) {
        this.isEditGroundMountOpen = false;
      }
      setIsLoading(false);
    },
  },
  computed: {
    ...mapGetters("groundMounts", [
      "getGroundMounts",
      "getIsLoadingGroundMounts",
    ]),
  },
};
</script>

<style scoped></style>

<template>
  <default-table-body-row>
    <v-col
      cols="12"
      sm="6"
      md="2"
    >
      <span class="d-block d-md-none font-weight-bold">SKU</span>
      <span class="primary--text font-weight-bold">{{ groundMount.sku }}</span>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <span class="d-block d-md-none font-weight-bold">Descrição</span>
      <span>{{ groundMount.insumo_descricao }}</span>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="3"
    >
      <span class="d-block d-md-none font-weight-bold">Divisor</span>
      <span class="primary--text font-weight-bold">{{
        groundMount.coeficiente
      }}</span>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="1"
    >
      <default-fab
        @click="$emit('openEditGroundMount', groundMount)"
        tooltip-text="Editar"
        :top="false"
        left
        ><v-icon>mdi-pencil</v-icon></default-fab
      >
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "@/shared/components/vuetify/DefaultTableBodyRow.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultFab,
    DefaultTableBodyRow,
  },
  props: {
    groundMount: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>

<template>
  <default-table-head>
    <v-col
      cols="2"
      class="border-r py-2"
    >
      SKU
    </v-col>
    <v-col
      cols="6"
      class="border-r py-2 text-truncate"
      >Descrição</v-col
    >
    <v-col
      cols="3"
      class="py-2"
      >Divisor</v-col
    >
  </default-table-head>
</template>

<script>
import DefaultTableHead from "@/shared/components/vuetify/DefaultTableHead.vue";

export default {
  name: "ListingHead",
  components: {
    DefaultTableHead,
  },
};
</script>

<style scoped></style>
